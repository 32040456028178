import { sort } from 'ramda';
import { useEffect, useMemo } from 'react';
import {
  Col,
  ErrorNotification,
  Row,
  InlineCarousel,
} from '@bt-healthcare/ui-toolkit';
import parse from 'date-fns/parse';
import type { TaskBarProps } from './types';
import { useTasks, useTasksDispatch } from 'context/tasks/TasksContext';
import {
  TaskListStatus,
  TaskListType,
  useTasksByStatusesQuery,
} from 'services/graphql';
import { FETCH_POLICY } from 'App.constants';
import {
  buildTasksListUI,
  TaskDueTypes,
} from 'components/TaskList/TasksList/utils';
import { DraftTaskNotification } from 'components/Tasks/Notifications/DraftTaskNotification';
import { PendingTaskNotification } from 'components/Tasks/Notifications/PendingTaskNotification';
import type { TaskAttributes } from 'components/TaskList/types';

const getDateString = (dateTime: string) =>
  parse(dateTime, 'dd/MM/yy', new Date()).toISOString();

const sortByCreatedDate = sort<TaskAttributes>((a, b) =>
  a.created.localeCompare(b.created)
);

const sortByDueDateTime = sort<TaskAttributes>((a, b) =>
  getDateString(a.dueDateTime).localeCompare(getDateString(b.dueDateTime))
);

export const TaskBar = ({
  wardPatientId,
  setShowTaskListPanel,
}: TaskBarProps) => {
  const tasksDispatch = useTasksDispatch();
  const { tasks } = useTasks();

  const { data, error, loading, refetch } = useTasksByStatusesQuery({
    variables: {
      wardPatientId,
      statuses: [
        TaskListStatus.Draft,
        TaskListStatus.Pending,
        TaskListStatus.Complete,
      ],
      limit: null,
    },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
  });

  const { overdueTasks, todayTasks, draftTasks } = useMemo(() => {
    const overdue: TaskAttributes[] = [];
    const today: TaskAttributes[] = [];
    const draft: TaskAttributes[] = [];

    tasks.forEach((task) => {
      const isPending = task.status === TaskListStatus.Pending;
      const isDraft = task.status === TaskListStatus.Draft;
      const isOverdue = task.dueType === TaskDueTypes.OVERDUE;
      const isToday = task.dueType === TaskDueTypes.TODAY;
      const isTypeSendInvite = [
        TaskListType.SendConsultationInvite,
        TaskListType.SendTestInvite,
      ].includes(task.type);

      if (isPending && isOverdue) {
        overdue.push(task);
      }
      if (isPending && isToday) {
        today.push(task);
      }
      if (isDraft && isTypeSendInvite) {
        draft.push(task);
      }
    });

    return {
      overdueTasks: sortByDueDateTime(sortByCreatedDate(overdue)),
      todayTasks: sortByCreatedDate(today),
      draftTasks: sortByCreatedDate(draft),
    };
  }, [tasks]);

  const showTasks = useMemo(
    () =>
      overdueTasks.length > 0 || todayTasks.length > 0 || draftTasks.length > 0,
    [overdueTasks, todayTasks, draftTasks]
  );

  useEffect(() => {
    if (data?.tasksByStatuses) {
      tasksDispatch({
        type: 'setTasks',
        tasks: buildTasksListUI(data.tasksByStatuses ?? []),
      });
    }
  }, [data?.tasksByStatuses, tasksDispatch]);

  if (!loading && error)
    return (
      <Row>
        <Col from={1}>
          <ErrorNotification
            space="none"
            id="task-bar"
            action="trying to load tasks"
            onTryAgainClick={refetch}
          />
        </Col>
      </Row>
    );

  if (!showTasks) {
    return null;
  }

  return (
    <Row>
      <Col from={1}>
        <InlineCarousel id="task-bar">
          {overdueTasks.map((task) => (
            <PendingTaskNotification key={task.id} task={task} />
          ))}
          {todayTasks.map((task) => (
            <PendingTaskNotification key={task.id} task={task} />
          ))}
          {draftTasks.map((task) => (
            <DraftTaskNotification
              key={task.id}
              task={task}
              setShowTaskListPanel={setShowTaskListPanel}
            />
          ))}
        </InlineCarousel>
      </Col>
    </Row>
  );
};
